import { createAction, props } from '@ngrx/store';
import { Appointment, AppointmentBody } from '@core/models/schedule.model';

const ActionTypes = {
  SET_APPOINTMENTS: '[Schedule] set appointments',
  FETCH_APPOINTMENTS: '[Schedule] fetch appointments',
  CREATE_APPOINTMENT: '[Schedule] create appointment',
  UPDATE_APPOINTMENT: '[Schedule] update appointment',
  DELETE_APPOINTMENT: '[Schedule] delete appointment',
  CONFIRM_PICKED_UP: '[Schedule] confirm picked up',
};

export const setAppointments = createAction(
  ActionTypes.SET_APPOINTMENTS,
  props<{
    appointments?: Appointment[],
    myWorkshopsAppointments?: Appointment[],
  }>()
);

export const fetchAppointments = createAction(
  ActionTypes.FETCH_APPOINTMENTS,
);

export const createAppointment = createAction(
  ActionTypes.CREATE_APPOINTMENT,
  props<{ appointment?: AppointmentBody }>()
);

export const updateAppointment = createAction(
  ActionTypes.UPDATE_APPOINTMENT,
  props<{ appointmentId: string; appointment?: AppointmentBody }>()
);

export const deleteAppointment = createAction(
  ActionTypes.DELETE_APPOINTMENT,
  props<{ appointmentId: string }>()
);

export const confirmPickedUp = createAction(
  ActionTypes.CONFIRM_PICKED_UP,
  props<{ appointmentId: string }>()
);
